import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import styled, { useTheme } from "styled-components"
import Paragraph from "@elements/paragraph"
import Flex from "@components/elements/flex"
import Div from "@components/elements/div"

const StyledAccordion = styled.div`
  overflow: hidden;
`

const StyledButton = styled.button`
  position: relative;
  display: inline-block;
  border: 0;
  background: none;
  width: 100%;
  transition: filter 0.2s ease;
  cursor: pointer;
  text-align: left;
  padding: 1rem 1rem 0 0;

  ${Paragraph} {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &:hover {
    ::after {
      border-left: 3px solid #4b57c5;
      border-bottom: 3px solid #4b57c5;
    }
  }

  ::after {
    position: absolute;
    top: ${props => (props.isOpen ? "29px" : "27px")};
    right: 3px;
    content: "";
    display: flex;
    float: right;
    text-transform: none;
    margin-left: auto;
    border-left: ${props =>
      props.isOpen ? "3px solid #4b57c5" : `3px solid ${props.color ?? "#000"}`};
    border-bottom: ${props =>
      props.isOpen ? "3px solid #4b57c5" : `3px solid ${props.color ?? "#000"}`};
    min-width: 10px;
    min-height: 10px;
    transform: ${props =>
      props.isOpen ? "rotateZ(-135deg)" : "rotateZ(-45deg)"};
    transition: all 150ms ease-in-out;
  }
`

const StyledPanel = styled.div`
  display: flex;
  flex-direction: column;
  max-height: ${props => (props.isOpen ? `${props.maxHeight}px` : `0`)};
  transition: 250ms max-height ease;
`

export const Accordion = ({ title, children, klips, textColor }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [maxHeight, setMaxHeight] = useState(0)
  const { color } = useTheme()

  const handleToggle = useCallback(() => {
    setIsOpen(prevOpen => !prevOpen)
  }, [setIsOpen])

  return (
    <StyledAccordion>
      <Div display="none">
        <svg xmlns="http://www.w3.org/2000/svg">
          <symbol id="icon-help-indigo">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 18H13V16H11V18ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12 6C9.79 6 8 7.79 8 10H10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 12 11 11.75 11 15H13C13 12.75 16 12.5 16 10C16 7.79 14.21 6 12 6Z"
                fill={klips ? color.blue400 : color.indigo400}
              ></path>
            </svg>
          </symbol>
        </svg>
      </Div>
      <StyledButton onClick={handleToggle} isOpen={isOpen} color={textColor}>
        <Flex flexFlow="row" gap="1rem" alignItems="center">
          <svg height="24px" width="25px" >
            <use xlinkHref="#icon-help-indigo"></use>
          </svg>
          <Paragraph
            color={klips ? color.blue600 : textColor ?? color.indigo600}
            fontSize="1.1rem"
            lineHeight={1.5}
            fontWeight={700}
          >
            {title}
          </Paragraph>
        </Flex>
      </StyledButton>
      <StyledPanel
        isOpen={isOpen}
        maxHeight={maxHeight}
        ref={ref => setMaxHeight(ref?.scrollHeight)}
      >
        <Flex gap="1rem" padding="1rem 0">
          {children}
        </Flex>
      </StyledPanel>
    </StyledAccordion>
  )
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  klips: PropTypes.bool,
  textColor: PropTypes.string
}
